<template>
	<errorContainer :error="erreur" :warning="warning">
		<div v-if="$screen.width <= 576" class="animated fadeIn">
			<div class="container-mobile">
				<div class="my-expense-mobile">
					<div class="title">
						<h1 class="main-page-title" :class="{ 'is-pwa': $isPwa() }">
							{{ FormMSG(1100, 'Purchase orders for crew') }}
						</h1>
					</div>
					<div class="my-expense-actions" :class="{ 'pt-16': $isPwa() }">
						<div class="d-flex justify-content-center wapper">
							<div class="btn-action text-center" @click="openCreatePoModal">
								<img src="svgs/po.svg" alt="Purshase order" />
								<span class="px-2">{{ FormMSG(2, 'Add Purchase Order') }}</span>
							</div>
						</div>
					</div>
					<div class="my-expense-status-actions">
						<div class="status-actions">
							<b-button-group>
								<b-button
									:pressed="notSubmittedState"
									:class="`not-submitted${notSubmittedState ? '' : ' not-active'}`"
									aria-pressed="true"
									@click="getPoList(0)"
									>{{ FormMSG(3, 'Not Submitted') }}</b-button
								>
								<b-button
									:pressed="submittedState"
									:class="`submitted${submittedState ? '' : ' not-active'}`"
									aria-pressed="true"
									@click="getPoList(1)"
									>{{ FormMSG(4, 'Submitted') }}</b-button
								>
							</b-button-group>
						</div>
					</div>
					<b-overlay :show.sync="isBusyDataTable" rounded="lg">
						<div class="content pb-3" v-if="pos.length > 0">
							<card-list-builder
								:use-new-version="true"
								:items="pos"
								:fields="mobileFields"
								:has-badge="true"
								:is-badge-use-props="true"
								:toggle-mode="true"
								badge-class="statusClass"
								badge-value="validatedStatus"
							>
								<template slot="actions" slot-scope="{ item }">
									<!-- update po -->
									<edit class="action cursor-pointer" :size="18" @click="editPoMobile(item)" v-if="notSubmittedState" />
									<!-- send single po to validation -->
									<send
										class="action cursor-pointer"
										:size="18"
										@click="handleSendPOtoValidation(item.id)"
										v-if="notSubmittedState && item.validated !== 4 && item.validated !== 16"
									/>
									<!-- delete po -->
									<trash class="action cursor-pointer" :size="18" @click="handleDeletePO(item)" color="#EA4E2C" v-if="notSubmittedState" />
									<!-- detail po -->
									<eye class="action cursor-pointer" :size="18" @click="editPoMobile(item)" v-if="submittedState" />
								</template>
							</card-list-builder>
						</div>
						<div v-else class="empty-data">
							<span v-if="notSubmittedState">{{ FormMSG(5, 'No refused or in waiting purchases orders to send') }}</span>
							<span v-else-if="submittedState">{{ FormMSG(6, 'No purchase orders sent') }}</span>
						</div>
					</b-overlay>
					<b-row class="footer-fixed" v-if="pos.length > 0 && notSubmittedState">
						<b-col cols="12">
							<b-button block size="lg" variant="primary" class="mb-2" @click="handSendAllPoValidation">
								<span>{{ FormMSG(7, 'Send po for validation') }}</span>
							</b-button>
						</b-col>
					</b-row>
				</div>
			</div>
		</div>
		<div class="" v-else>
			<b-row align-h="between">
				<b-col md="6">
					<h1 :class="[`${!$screen.sm ? 'main-page-title' : ''}`, { 'is-pwa': $isPwa() }]">
						{{ FormMSG(1100, 'Purchase orders for crew') }}
					</h1>
				</b-col>
				<b-col md="6" class="text-right">
					<b-button variant="primary" @click="openCreatePoModal">
						{{ FormMSG(9, 'Create PO') }}
					</b-button>
				</b-col>
			</b-row>
			<b-row>
				<b-col cols="12" xl="12">
					<b-card no-body class="mt-2">
						<b-tabs v-model="activeListTab" card>
							<b-tab :title="FormMSG(10, 'Not submitted or refused POs')" @click="getPoList(0)">
								<b-card-text class="py-0">
									<b-overlay :show.sync="isBusyDataTable" rounded="lg">
										<div v-if="unsentOrRefusedPurchaseOrders.length === 0" class="empty-state">
											{{ FormMSG(11, 'No refused or in waiting purchases orders to send') }}
										</div>
										<div v-else>
											<b-row align-h="between" :class="`${$screen.width >= 992 ? 'mb-1' : ''}`">
												<b-col cols="6" class="pb-3">
													<b-input-group>
														<b-form-input
															id="po-not-submitted"
															v-model="first_filter"
															type="text"
															:placeholder="FormMSG(28, 'Type to Search')"
														/>
														<b-input-group-append class="cursor-pointer">
															<b-input-group-text class="btn-search">
																<component
																	:is="getLucideIcon(ICONS.SEARCH.name)"
																	:color="ICONS.SEARCH.color"
																	:size="16"
																	class="icon"
																	:stroke-width="2.5"
																	v-if="first_filter.length === 0"
																/>
																<component
																	:is="getLucideIcon(ICONS.X.name)"
																	:color="ICONS.X.color"
																	:size="16"
																	class="icon"
																	:stroke-width="2.5"
																	@click="first_filter = ''"
																	v-else
																/>
															</b-input-group-text>
														</b-input-group-append>
													</b-input-group>
												</b-col>
												<b-col md="6" class="text-right pb-2">
													<b-button
														variant="primary"
														v-html="FormMSG(12, 'Send po for validation')"
														style="margin-left: 20px"
														@click="handSendAllPoValidation"
													/>
												</b-col>
											</b-row>
											<b-table
												ref="unsentOrRefusedPurchaseOrderTable"
												:hover="hover"
												selectable
												:filter="first_filter"
												:selectedVariant="selectedColor"
												:select-mode="selectMode"
												sticky-header="800px"
												:per-page="currentTablePagePerPage"
												:items="unsentOrRefusedPurchaseOrders"
												:fields="unsentOrRefusedPurchaseOrderFields"
												:head-variant="hv"
												bordered
												small
												@row-clicked="handleRowClicked"
												:empty-text="FormMSG(300, 'No data found')"
												show-empty
											>
												<template #cell(sendtoapp)="data">
													<b-button
														v-if="data.item.validated !== 4 && data.item.validated !== 16"
														size="sm"
														class="btn bg-transparent border-0"
														@click="handleSendPOtoValidation(data.item.id)"
													>
														<component :is="getLucideIcon(ICONS.SEND.name)" :color="ICONS.SEND.color" :size="20" />
													</b-button>
												</template>
												<template #cell(rem)="data">
													<b-button size="sm" class="btn bg-transparent border-0" @click="handleDeletePO(data.item)">
														<component :is="getLucideIcon(ICONS.TRASH.name)" :color="ICONS.TRASH.color" :size="20" />
													</b-button>
												</template>
												<template #cell(validated)="data">
													<div class="wrap-status">
														<div :class="`status ${data.item.statusClass}`" style="font-size: 0.7rem">
															{{ data.item.validatedStatus }}
														</div>
													</div>
												</template>
											</b-table>
										</div>
									</b-overlay>
								</b-card-text>
							</b-tab>
							<b-tab :title="FormMSG(13, 'List of submitted POs')" @click="getPoList(1)">
								<b-overlay :show.sync="isBusyDataTable" rounded="lg">
									<div v-if="submittedPurchaseOrders.length === 0" class="empty-state">
										{{ FormMSG(14, 'No purchase orders sent') }}
									</div>
									<div v-else>
										<b-row align-h="between" :class="`${$screen.width >= 992 ? 'mb-1' : ''}`">
											<b-col cols="6" class="pb-3">
												<b-input-group>
													<b-form-input
														id="po-submitted"
														v-model="second_filter"
														type="text"
														:placeholder="FormMSG(28, 'Type to Search')"
													/>
													<b-input-group-append class="cursor-pointer">
														<b-input-group-text class="btn-search">
															<component
																:is="getLucideIcon(ICONS.SEARCH.name)"
																:color="ICONS.SEARCH.color"
																:size="16"
																class="icon"
																:stroke-width="2.5"
																v-if="second_filter.length === 0"
															/>
															<component
																:is="getLucideIcon(ICONS.X.name)"
																:color="ICONS.X.color"
																:size="16"
																class="icon"
																:stroke-width="2.5"
																@click="second_filter = ''"
																v-else
															/>
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</b-col>
										</b-row>
										<b-table
											ref="submittedPurchaseOrderTable"
											:hover="hover"
											selectable
											:selectedVariant="selectedColor"
											:select-mode="selectMode"
											bordered
											small
											:filter="second_filter"
											sticky-header="700px"
											:head-variant="hv"
											:per-page="currentTablePagePerPage"
											responsive="sm"
											:current-page="currentTablePageSubmitedPO"
											:items="submittedPurchaseOrders"
											:fields="submitedPurchaseOrdersFields"
											@row-clicked="handleRowClicked"
											:empty-text="FormMSG(300, 'No data found')"
											show-empty
										>
											<template #cell(validated)="data">
												<div class="wrap-status">
													<div :class="`status ${data.item.statusClass}`" style="font-size: 0.7rem">
														{{ data.item.validatedStatus }}
													</div>
												</div>
											</template>
										</b-table>
									</div>
								</b-overlay>
							</b-tab>
						</b-tabs>
					</b-card>
				</b-col>
			</b-row>
		</div>
		<PoService
			:useComponent="true"
			v-model="isPoServiceModalOpen"
			:edit-data="currentPOSelected"
			@closed="handlePOModalClosed"
			@submitted="handlePOModalSubmitted"
			@refresh="handlePOModalRefresh"
			@handleActivePoModalContract="handleActivePoModalContract"
		/>
	</errorContainer>
</template>

<script>
import { mapActions } from 'vuex';
import Loading from 'vue-loading-overlay';
import { isNil } from '~utils';
import { rendCurrency, formatTableDate } from '~helpers';
import languageMessages from '@/mixins/languageMessages';
import { getTextFromMenuNumberAndMenuValue } from '@/cruds/language.crud';
import PoService from '@/components/PoService/Main';
import { getProjectUserPoDetail, SendPurchaseOrdersSlice } from '@/cruds/budget.crud';
import globalMixin from '@/mixins/global.mixin';
import { store } from '@/store';
import { Edit, Eye, Send, Trash2 as Trash } from 'lucide-vue';

const validatedText = async (validated) => {
	let value = null;
	await new Promise((resolve, reject) => {
		resolve(getTextFromMenuNumberAndMenuValue(1008, validated));
		reject(false);
	}).then((data) => {
		value = data;
	});

	return value;
};

const validatedColor = (validated) => {
	let retval = 'not-submitted';
	if (validated == 1) {
		retval = 'info';
	} else if (validated == 2) {
		retval = 'pending';
	} else if (validated == 8) {
		retval = 'validated';
	} else if (validated == 4 || validated == 16) {
		retval = 'refused';
	}
	return retval;
};
export default {
	name: 'PoEntryCrew',
	components: {
		Loading,
		PoService,
		Edit,
		Eye,
		Send,
		Trash
	},
	mixins: [languageMessages, globalMixin],
	props: {
		hover: {
			type: Boolean,
			default: true
		},
		striped: {
			type: Boolean,
			default: true
		},
		bordered: {
			type: Boolean,
			default: true
		},
		small: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			hv: 'dark',
			Validated: 0,
			isNotSubmitedPOLoading: false,
			isSubmitedPOLoading: false,
			erreur: {},
			warning: '',
			currentPOSelected: null,
			currentTablePage: 1,
			perPage: 0,
			filter: '',
			currentTablePageSubmitedPO: 1,
			currentTablePagePerPage: 0,
			isPoServiceModalOpen: false,
			activeListTab: 0,
			unsentOrRefusedPurchaseOrders: [],
			submittedPurchaseOrders: [],
			isSubmitted: false,
			notSubmittedState: true,
			submittedState: false,
			pos: [],
			isBusyDataTable: false,
			selectMode: 'single',
			selectedColor: 'primary',
			posId: [],
			first_filter: '',
			second_filter: ''
		};
	},
	computed: {
		__baseTable() {
			const _t = this.FormMSG;
			return [
				{
					key: 'id',
					label: '#' + _t(31311, 'Id'),
					formatter: (v) => `${v}`,
					sortable: true
				},
				{
					key: 'validated',
					label: _t(31312, 'Status'),
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'fullName',
					label: _t(31330, 'Crew member'),
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'requestDate',
					label: _t(31412, 'Request date'),
					formatter: (v) => formatTableDate(v),
					sortable: true
				},
				{
					key: 'deliveryDate',
					label: _t(31413, 'Delivery date'),
					formatter: (v) => formatTableDate(v),
					sortable: true
				}
			];
		},
		unsentOrRefusedPurchaseOrderFields() {
			const _t = this.FormMSG;
			return [
				...this.__baseTable,
				{
					key: 'description',
					label: _t(31315, 'Description'),
					sortable: true
				},
				{
					key: 'supplierName',
					label: _t(31316, 'Supplier Name'),
					sortable: true
				},
				{
					key: 'departmentName',
					label: _t(31317, 'Department'),
					formatter: (v) => v.toLowerCase(),
					sortable: true
				},
				{
					key: 'amount',
					label: _t(31318, 'Amount'),
					formatter: (v) => rendCurrency(v),
					sortable: true,
					class: 'text-right'
				},
				{
					key: 'sendtoapp',
					label: _t(31319, 'Send'),
					sortable: false,
					formatter: (v) => v,
					class: 'text-center'
				},
				{
					key: 'rem',
					label: _t(31320, 'Delete'),
					sortable: false,
					formatter: (v) => v,
					class: 'text-center'
				}
			];
		},
		unsentOrRefusedPurchaseOrderMobileFields() {
			const keys = ['sendtoapp', 'validated', 'rem'];
			return this.unsentOrRefusedPurchaseOrderFields.filter((t) => !keys.includes(t.key));
		},
		submitedPurchaseOrdersFields() {
			const _t = this.FormMSG;
			return [
				...this.__baseTable,
				{
					key: 'description',
					label: _t(31414, 'Description'),
					sortable: true
				},
				// {
				// 	key: 'comment',
				// 	label: _t(31418, 'Comment'),
				// 	formatter: v => (isEmpty(v) ? '/' : v),
				// 	sortable: true
				// },
				{
					key: 'supplierName',
					label: _t(31415, 'Supplier Name'),
					sortable: true
				},
				{
					key: 'departmentName',
					label: _t(31416, 'Department'),
					formatter: (v) => v.toLowerCase(),
					sortable: true
				},
				{
					key: 'amount',
					label: _t(31417, 'Amount'),
					formatter: (v) => rendCurrency(v),
					sortable: true,
					class: 'text-right'
				}
			];
		},
		submitedPurchaseOrdersMobileFields() {
			const keys = ['validated'];
			return this.submitedPurchaseOrdersFields.filter((t) => !keys.includes(t.key));
		},
		mobileFields() {
			if (this.notSubmittedState) {
				return this.unsentOrRefusedPurchaseOrderMobileFields;
			} else if (this.submittedState) {
				return this.submitedPurchaseOrdersMobileFields;
			}
		}
	},
	async created() {
		await this.reload();
	},
	methods: {
		...mapActions({
			deletePurchaseOrder: 'purchaces/deletePurchaseOrder',
			sendToValidation: 'purchaces/sendToValidation',
			destroySelectedPO: 'purchaces/destroySelectedPO'
		}),
		handleActivePoModalContract(e) {
			this.isPoServiceModalOpen = e;
		},
		async openCreatePoModal() {
			this.currentPOSelected = null;
			this.isPoServiceModalOpen = true;
		},
		handSendAllPoValidation() {
			const txt = this.FormMSG(12121112, 'Please confirm that you want to send all purchase orders to validation');

			const vm = this;

			this.$bvModal
				.msgBoxConfirm(txt, {
					title: this.FormMSG(434242, 'Please Confirm'),
					size: 'sm',
					buttonSize: 'sm',
					okVariant: 'primary',
					cancelVariant: 'light',
					okTitle: this.FormMSG(121212, 'YES'),
					cancelTitle: this.FormMSG(121213, 'NO'),
					footerClass: 'p-2',
					hideHeaderClose: false,
					centered: false
				})
				.then(async (s) => {
					if (!s) return;
					await SendPurchaseOrdersSlice(this.posId).then(async () => {
						this.createToastForMobile(this.FormMSG(10, 'Success'), this.FormMSG(13, 'Pos were sent for validation.'));
						this.activeListTab = 1;
						this.notSubmittedState = false;
						this.submittedState = true;
						await this.reload();
					});
				})
				.catch((err) => console.log(err));
		},
		/**
		 * @param {Object} po - purchase order selected
		 */
		handleRowClicked(po) {
			this.currentPOSelected = po;
			this.isPoServiceModalOpen = true;
		},
		handlePOModalClosed() {
			this.currentPOSelected = null;
			this.isPoServiceModalOpen = false;
		},
		/**
		 * @param {Number} id - purchaseOrderID
		 */
		handleSendPOtoValidation(id) {
			if (isNil(id)) return;

			const txt = this.FormMSG(121211, 'Please confirm that you want to send this purchase order to validation');

			this.$bvModal
				.msgBoxConfirm(txt, {
					title: this.FormMSG(434242, 'Please Confirm'),
					size: 'sm',
					buttonSize: 'sm',
					okVariant: 'success',
					cancelVariant: 'light',
					okTitle: this.FormMSG(121212, 'YES'),
					cancelTitle: this.FormMSG(121213, 'NO'),
					footerClass: 'p-2',
					hideHeaderClose: false,
					centered: false
				})
				.then(async (s) => {
					if (!s) return;
					await this.sendToValidation(id).then(async () => {
						this.createToastForMobile(this.FormMSG(10, 'Success'), this.FormMSG(14, 'Po was sent for validation.'));
						this.activeListTab = 1;
						this.notSubmittedState = false;
						this.submittedState = true;
						await this.reload();
					});
				})
				.catch((err) => console.log(err));
		},
		/**
		 * @param {Object} po
		 */
		handleDeletePO(po) {
			if (isNil(po)) return;

			const txt = this.FormMSG(121214, 'Please confirm that you want delete this purchase order');

			this.$bvModal
				.msgBoxConfirm(txt, {
					title: this.FormMSG(434242, 'Please Confirm'),
					size: 'sm',
					buttonSize: 'sm',
					okVariant: 'danger',
					okTitle: this.FormMSG(121212, 'YES'),
					cancelTitle: this.FormMSG(121213, 'NO'),
					footerClass: 'p-2',
					hideHeaderClose: false,
					centered: false
				})
				.then((s) => {
					if (!s) return;

					this.deletePurchaseOrder(po.id).then(async () => {
						await this.reload();
					});
				})
				.catch((err) => console.log(err));
		},
		async handlePOModalSubmitted(payload) {
			if (payload) {
				this.activeListTab = 0;
				this.notSubmittedState = true;
				this.submittedState = false;

				this.isPoServiceModalOpen = false;
			}
			await this.reload();
		},
		async reload() {
			this.currentPOSelected = null;
			this.isBusyDataTable = true;
			this.pos = [];
			if (this.activeListTab === 0 || this.notSubmittedState) {
				await getProjectUserPoDetail({ notSubmitted: true, groupBySheets: true, activateCrewAccessRestriction: true })
					.then((records) => {
						let Pos = [];
						records.map(async (po) => {
							po.purchaseOrder.fullName = po.purchaseOrder.user.name + ' ' + po.purchaseOrder.user.firstName;
							po.purchaseOrder.statusClass = validatedColor(po.purchaseOrder.validated);
							po.purchaseOrder.validatedStatus = await validatedText(po.purchaseOrder.validated);
							Pos.push(po.purchaseOrder);
							this.posId.push(parseInt(po.purchaseOrder.id, 10));
						});
						this.unsentOrRefusedPurchaseOrders = Pos;
						this.pos = Pos;
					})
					.finally(() => {
						this.isBusyDataTable = false;
					});
			} else if (this.activeListTab === 1 || this.submittedState) {
				await getProjectUserPoDetail({ submitted: true, groupBySheets: true, activateCrewAccessRestriction: true })
					.then((records) => {
						let Pos = [];
						records.map(async (po) => {
							po.purchaseOrder.fullName = po.purchaseOrder.user.name + ' ' + po.purchaseOrder.user.firstName;
							po.purchaseOrder.statusClass = validatedColor(po.purchaseOrder.validated);
							po.purchaseOrder.validatedStatus = await validatedText(po.purchaseOrder.validated);
							Pos.push(po.purchaseOrder);
						});
						this.submittedPurchaseOrders = Pos;
						this.pos = Pos;
					})
					.finally(() => {
						this.isBusyDataTable = false;
					});
			}
		},
		async handlePOModalRefresh(payload) {
			if (payload) {
				await this.reload();
			}
		},
		handleTabChanged(status) {
			switch (status) {
				case 0:
					this.notSubmittedState = true;
					this.submittedState = false;
					break;
				case 1:
					this.notSubmittedState = false;
					this.submittedState = true;
					break;
			}
			this.activeListTab = status;
		},
		async getPoList(status) {
			this.handleTabChanged(status);
			await this.reload();
		},
		editPoMobile(item) {
			this.currentPOSelected = item;
			this.isPoServiceModalOpen = true;
		},
		viewPoMobile(item) {
			console.log({ item });
		}
	}
};
</script>

<style scoped></style>
